<template>
  <div class="flex w-full justify-center">
    <portal to="actions">

      <PButton
        class="ml-4"
        @click="showLocationDialog = true"
        color="primary"
      >
        <span v-html="$tk('ExternalLocations.NewExternalLocation')"></span>
      </PButton>

    </portal>

    <PLoader v-if="loading" />

    <transition
      v-else
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-100"
      leave-class="opactity-100"
      leave-to-class="opacity-0"
    >
    
      <PTable
        v-if="locations"
        @select="editLocation"
        class="mb-auto"
        :hover="true"
        :cols="cols"
        :data="locations"
      ></PTable>

    </transition>

    <ExternalLocationDialog
      @close="closeLocationDialog"
      @updated="loadLocations"
      :recId="selectedLocation"
      :existingIds="existingLocationIds"
      v-if="showLocationDialog"
    />

  </div>
</template>

<script>
import api from "../api";
import ExternalLocationDialog from "./dialogs/ExternalLocationDialog.vue";
import { mapGetters } from "vuex";
import { map } from "lodash";

export default {
  name: "ExternalLocations",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locations: [],
      showLocationDialog: false,
      selectedLocation: "",
      loading: true,
      cols: [
        { text: this.$tk("ExternalLocations.LocationNumber"), value: "id", sortable: true },
        { text: this.$tk("ExternalLocations.ContactName"), value: "contactName", sortable: false },
        { text: this.$tk("Common.General.Name"), value: "name", sortable: true },
        { text: this.$tk("Common.Address.Street"), value: "street", sortable: false },
        { text: this.$tk("Common.Address.PostCode"), value: "postalCode", sortable: false },
        { text: this.$tk("Common.Address.PostPlace"), value: "postalName", sortable: false },
        { text: this.$tk("Common.Address.Country"), value: "countryId", sortable: false },
      ],
    };
  },
  components: {
    ExternalLocationDialog,
  },
  computed: {
    ...mapGetters(["customer"]),
    existingLocationIds() {
      return map(this.locations, (item) => {
        return item.idExt;
      });
    },
  },
  methods: {
    async editLocation(value) {
      this.selectedLocation = value.id; // Set location id
      this.showLocationDialog = true; // Open window
    },
    async createLocation() {
      this.selectedLocation = "";
      this.showLocationDialog = true;
    },
    async closeLocationDialog() {
      this.selectedLocation = "";
      this.showLocationDialog = false;
    },
    async loadLocations() {
      this.loading = true;
      this.locations = await api.externalLocationsGet(this.customer.id);
      this.loading = false;
    },
  },
  async created() {
    await this.loadLocations();
  },
};
</script>
